<script setup lang="ts">
import {IFormItem} from "@/interfaces/Form";

import FormTextInfo from "@/components/form/FormTextInfo.vue";

const options:IFormItem[] = [
  {
    key: 'region',
    label: 'Регион',
    type: 'string'
  },
  {
    key: 'city',
    label: 'Город',
    type: 'string'
  }
]
</script>

<template>
  <div class="client-place">
    <div class="grid grid-cols-2 gap-4">
      <div
          v-for="option in options"
          :key="option.key"
      >
        <FormTextInfo :key="option.key"
                      :label="option.label"
        />
      </div>
    </div>
  </div>
</template>
