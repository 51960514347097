<script setup lang="ts">

</script>

<template>
  <div class="app-header-user">
    <div class="app-header-user__container">
      <div class="app-header-user__right">
        <h6 class="app-header-user__name">
          Константин Медрин
        </h6>
        <h6 class="app-header-user__role">
          Админиистратор
        </h6>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.app-header-user {
  &__name,
  &__role {
    font-weight: 600;
  }


  &__name {
    margin-bottom: 2px;
    color: #1D1D1F;
    font-size: 16px;
  }

  &__role {
    color: #A8A8B2;
    font-size: 14px;
  }
}
</style>
