<script setup lang="ts">
import {ref} from 'vue';

const {valueDefault, label, onChange, key} = defineProps({
  valueDefault: {
    type: [String,Number],
    required: false,
    default: ''
  },
  label: {
    type: String,
    required: false,
    default: ''
  },
  onChange: {
    type: Function,
    required: false,
    default: null
  },
  key: {
    type: String,
    required: true
  }
})

const value = ref<string|number>(valueDefault);

const handleChange = () => {
  if(onChange) onChange(key, value);
}
</script>

<template>
  <div class="form-text-info">
    <label class="form-text-info__label"
           :for="label"
    >{{ label }}</label>
    <a-input
        :id="label"
        v-model:value="value"
        placeholder=""
        @change="handleChange"
        class="form-text-info__value"
    />
  </div>
</template>

<style lang="scss" scoped>
.form-text-info {
  &__label {
    margin-bottom: .5rem;
  }
}
</style>
