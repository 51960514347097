<script setup lang="ts">
import {ref} from 'vue';
import {ITableAction} from "@/interfaces/Tabs";

import { SmallDashOutlined } from '@ant-design/icons-vue';

import ModalMassEdit from "@/components/modal/ModalMassEdit.vue";
import ClientModalContent from "@/components/user-modal/ClientModalContent.vue";

const actions:ITableAction[] = [
  {
    key: 'add',
    name: 'Добавить клиента'
  },
  {
    key: 'download',
    name: 'Загрузить клиентов'
  },
  {
    key: 'edit',
    name: 'Редактировать'
  },
  {
    key: 'archive',
    name: 'Архивировать'
  }
];

const isShowModal = ref<boolean>(false);
const activeView = ref<string>('');

const handleShowModal = (action:ITableAction) => {
  isShowModal.value = true;
  activeView.value = action.key;
}

const onHideModal = () => {
  isShowModal.value = false;
  activeView.value = '';
}
</script>

<template>
  <div class="ocm-dropdown">
    <a-dropdown class="ocm-dropdown__elem">
      <a class="ant-dropdown-link" @click.prevent>
        <SmallDashOutlined />
      </a>
      <template #overlay>
        <a-menu>
          <a-menu-item
              v-for="menuItem in actions"
              :key="menuItem.key"
          >
            <a class="ocm-dropdown__item"
               @click="handleShowModal(menuItem)"
            >{{ menuItem.name }}</a>
          </a-menu-item>
        </a-menu>
      </template>
    </a-dropdown>

    <a-modal v-model:open="isShowModal"
             :title="null"
             :footer="null"
    >
      <ModalMassEdit
          v-if="activeView === 'edit'"
          :onHideModal="onHideModal"
      />

      <ClientModalContent
        v-if="activeView === 'add'"
        :close-modal="onHideModal"
      />
    </a-modal>
  </div>
</template>
