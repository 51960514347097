<script setup lang="ts">
import {ref} from 'vue';

import ClientModalContent from "@/components/user-modal/ClientModalContent.vue";

const isShowModal = ref<boolean>(false);

const handleEditProfile = () => isShowModal.value = true;

const handleCloseModal = () => isShowModal.value = false;
</script>

<template>
  <div class="cell-table-control flex gap-2 items-center justify-end">
    <a-modal v-model:open="isShowModal"
             title="Редактирование профиля"
    >
      <template #footer>
        <div></div>
      </template>

      <ClientModalContent :closeModal="handleCloseModal" />
    </a-modal>

    <button class="ocm-btn--icon"
            @click="handleEditProfile"
    >
      <img src="/images/icon-table-edit.svg"
           alt=""
      />
    </button>
    <button class="ocm-btn--icon">
      <img src="/images/icon-cell-archive.svg"
           alt=""
      />
    </button>
  </div>
</template>
