<script setup lang="ts">
import {ref, reactive} from 'vue';

import {sex} from '@/enums/form';

import FormTextInfo from "@/components/form/FormTextInfo.vue";

import type {IClientPersonalData} from "@/interfaces/Clients";
import {IFormItem} from "@/interfaces/Form";

const personalData = ref<IClientPersonalData>({
  id: 1,
  name: '',
  lastname: '',
  surname: '',
  sex: ''
});
const personalDataOptions:IFormItem[] = [
  {
    key: 'id',
    label: 'ID',
    type: 'string'
  },
  {
    key: 'name',
    label: 'Фамилия',
    type: 'string'
  },
  {
    key: 'lastname',
    label: 'Имя',
    type: 'string'
  },
  {
    key: 'sex',
    label: 'Пол',
    type: 'select'
  }
];

const radioStyle = reactive({
  display: 'flex',
  height: '30px',
  lineHeight: '30px',
});
</script>

<template>
  <div class="client-personal-data flex">
    <div class="grid grid-cols-2 gap-4">
      <div
           v-for="option in personalDataOptions"
           :key="option.key"
      >
        <FormTextInfo
            :key="option.key"
            :label="option.label" />
      </div>
    </div>

    <div class="client-personal-data__sex">
      <label class="filter-aside__label mb-3">
        Пол
      </label>
      <a-radio-group v-model:value="personalData.sex">
        <a-radio
            v-for="option in sex"
            :key="option"
            :style="radioStyle" :value="option">{{ option }}</a-radio>
      </a-radio-group>
    </div>
  </div>
</template>

<style scoped lang="scss">
.client-personal-data {
  flex-direction: column;
  gap: 20px;
}
</style>
