<script setup lang="ts">
import type {IFormItem} from "@/interfaces/Form";

import FormTextInfo from "@/components/form/FormTextInfo.vue";

const personalDataOptions:IFormItem[] = [
  {
    key: 'phone',
    label: 'Основной телефон',
    type: 'string'
  },
  {
    key: 'secondPhone',
    label: 'Второй телефон',
    type: 'string'
  },
  {
    key: 'mail',
    label: 'Основная почта',
    type: 'string'
  },
  {
    key: 'secondMail',
    label: 'Вторая почта',
    type: 'select'
  },
  {
    key: 'telegram',
    label: 'Telegram',
    type: 'select'
  },
  {
    key: 'whatsApp',
    label: 'WhatsApp',
    type: 'select'
  },
  {
    key: 'viber',
    label: 'Viber',
    type: 'select'
  },
  {
    key: 'vk',
    label: 'Vk',
    type: 'select'
  },
  {
    key: 'ok',
    label: 'Одноклассники',
    type: 'select'
  }
];
</script>

<template>
  <div class="contact-info">
    <div class="grid grid-cols-2 gap-4">
      <div
          v-for="option in personalDataOptions"
          :key="option.key"
      >
        <FormTextInfo :key="option.key"
                      :label="option.label"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
