<script setup lang="ts">
import type {ISection} from '@/interfaces/Sections';

import ClientPersonalData from "@/components/user-modal/ClientPersonalData.vue";
import FilterAsideBlock from "@/components/filters/filter-aside/FitlerAsideBlock.vue";
import ClientContactInfo from './ContactInfo.vue';
import ClientPlace from './ClientPlace.vue';

const {closeModal} = defineProps({
  closeModal: {
    type: Function,
    required: false,
    default: null
  }
});

const sections:ISection[] = [
  {
    key: '',
    content: ClientPersonalData,
    title: 'Пеосональные данные'
  },
  {
    key: 'contact-info',
    content: ClientContactInfo,
    title: 'Контактная информация'
  },
  {
    key: '',
    content: ClientPlace,
    title: 'Местоположение'
  },
  {
    key: '',
    content: null,
    title: 'Профессиональная информация'
  }
];

const close = () => {
  if(closeModal) closeModal();
}
</script>

<template>
  <div class="client-modal-content">
    <div class="client-modal-content__sections flex">
      <div class="client-modal-content__section"
           v-for="section in sections"
           :key="section.key"
      >
        <FilterAsideBlock
            :title="section.title"
        >
          <div v-if="section.content !== null">
            <Component :is="section.content" />
          </div>
        </FilterAsideBlock>
      </div>
    </div>

    <div class="client-modal-content__footer flex gap-4 items-center">
      <button class="ocm-btn ocm-btn" @click="close">Применить</button>
      <button class="ocm-btn ocm-btn--secondary" @click="close">Отмена</button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.client-modal-content {
  &__sections {
    margin-bottom: 2rem;
    flex-direction: column;
    gap: 24px;
  }
}
</style>
